/*
* 保存一些基础通用api
* @Author:Mumu
* @Date 2022/09/13
*/

const apiList	=	{
	// 获取地区
	getaddress:{
		method:'get',
		url:'/api/company/addresslist',
		desc:'获取地区'
	},
	getindustry:{
		method:'get',
		url:'/api/company/industrylist',
		desc:'获取行业'
	},
	companylist:{
		method:'post',
		url:'/api/company/list',
		desc:'获取行业'
	},
	companydetail:{
		method:'get',
		url:'/api/company/detail',
		desc:'获取公司详情'
	},
	addressranking:{
		method:'get',
		url:'/api/address/ranking',
		desc:'获取公司详情'
	},
	statistics:{
		method:'get',
		url:'/api/company/statistics',
		desc:'获取统计'
	},
	financelist:{
		method:'get',
		url:'/api/finance/companylist',
		desc:'获取财务列表'
	},
	companyrank:{
		method:'get',
		url:'/api/company/companyrank',
		desc:'公司排名'
	}
}

export default apiList