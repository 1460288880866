import Cache from './cache-mu.js';
import muajax from './axios-core-mu.js';
import { Modal } from 'ant-design-vue';
import export_xls from './export_xls.js'
/**
 * 公共数据
 * @type {Object}
 */
const myData={

};
/**
 * 公共处理方法
 * @Author Mumu
 * @type {Object}
 */
const myfun={
	/**
	 * 同步或异步确认框
	 * @Author   Mumu
	 * @DateTime 2022-01-26T10:57:11+0800
	 * @param    {Fucntion,String}        [description]
	 * @param    {String}                 [description]
	 * @param    {String}                 [description]
	 * @return   {null,Promise}           [description]
	 * @usage    showfirm(callback,content,title)    await showfirm(content,title)
	 */
	showfirm(){
		if(typeof arguments[0] == 'function'){
			let [callback,content,title='']=arguments;
			Modal.confirm({
				title:title||'',
				content:content||'确定要进行该操作吗',
				onOk:()=>{
					callback()
				},
			});
		}else{
			let [content,title='']=arguments;
			return new Promise(resolve=>{
				Modal.confirm({
					title:title||'',
					content:content||'确定要进行该操作吗',
					onOk:()=>{
						resolve(true)
					},
					onCancel:()=>{
						resolve(false)
					}
				});
			})
		}
	},
	/**
	 * 格式化一个时间戳
	 * @param  {[type]} fmt 格式化方式   说明：
						Y   4位数字年，y为2位数字，如99即1999年
						m   数字月份，有前导0 M无前导0
						d   月份中的第几天，有前导0 D无前导0
						h   24小时格式，有前导0，H无前导0
						g   12小时格式，有前导0，G无前导0
						i   分钟格式，有前导0  I无前导0
						s   秒格式，有前导0  S无前导0
	 * @param  {[type]} s   需要 返回的时间参数  默认fmt
	 * @param  {[type]} tm   使用时间 默认当前时间
	 * @return {[type]}     [description]
	 */
	timef(tm,fmt,allobj){
		if(tm==='now'){ tm=new Date() }
		if(!tm){return ''}
		if (!fmt) { fmt='Y-m-d H:i:s' }
		var t = {       fmt       :        fmt        ||       'Y-m-d H:i:s'       };
		tm    = (isNaN(tm)||tm.constructor==Date)?tm:(parseInt(tm)*1000||new Date());
		var today=new Date(tm);
		t.time=	today.getTime();
		t.Y   =  today.getFullYear();
		t.y   =  (t.Y+'').slice(-2);
		t.M   =  today.getMonth()+1;
		t.m   =  t.M<10?"0"+t.M:t.M;
		t.D   =  today   .getDate();
		t.d   =  t.D<10?"0"+t.D:t.D;
		t.H   =  today  .getHours();
		t.h   =  t.H<10?"0"+t.H:t.H;
		t.G   =  t.H>12? t.H-12:t.H;
		t.g   =  t.G<10?"0"+t.G:t.G;
		t.I   =  today.getMinutes();
		t.i   =  t.I<10?"0"+t.I:t.I;
		t.S   =  today.getSeconds();
		t.s   =  t.S<10?"0"+t.S:t.S;
		t.K   =  today.getDay();
		t.fmt = t.fmt.replace(/Y/g,t.Y)
					 .replace(/y/g,t.y)
					 .replace(/M/g,t.M)
					 .replace(/m/g,t.m)
					 .replace(/D/g,t.D)
					 .replace(/d/g,t.d)
					 .replace(/H/g,t.H)
					 .replace(/h/g,t.h)
					 .replace(/G/g,t.G)
					 .replace(/g/g,t.g)
					 .replace(/I/g,t.I)
					 .replace(/i/g,t.i)
					 .replace(/S/g,t.S)
					 .replace(/s/g,t.s)
					 .replace(/K/g,['日','一','二','三','四','五','六'][t.K]);
		if (!!allobj) {return t; }
		return t.fmt;
	},
	uploadFileUrl:process.env.VUE_APP_API_BASE_URL+'/home/uploadFile',//附件统一上传地址
	async previewParse(){
		return 'https://cdn.eleadmin.com/20200609/ic_file_text_sm.png';
	},//上传插件需要1个promise作为列表ico封面
	commonHeaders(){
		let headers={};
		// let token = setting.takeToken();
		// if (token && token !== 'Bearer null') {
		// 	headers[setting.tokenHeaderName] = token;
		// }
		return headers;
	},//通用 api headers
	winback(){
		// this.$store.dispatch('user/tabRemove', this.$router.currentRoute.value.name);
        // this.$router.go(-1);
	},//关闭当前标签并回到上一个页面
	errorFocus(err,refform,box=window){
		console.log(err.errorFields[0].name)
		if(err.errorFields[0].name.length>1){
			let inputId = err.errorFields[0].name.join('_');
			console.log('错误聚焦到指定id：#'+inputId)
			document.querySelector('#'+inputId).focus();
		}else{
			console.log('错误自动聚焦指定field：'+err.errorFields[0].name[0])
			if(!refform){
				refform=this.$refs.forms;
			}
			refform.scrollToField(err.errorFields[0].name[0],{behavior: actions=>{
				actions.forEach(({ el, top, left }) => {
					el.scrollTop = top-20;
			    })
			}});
		}
		// let area = document.querySelector('#'+inputId).getBoundingClientRect();
		// console.log(area)
		// if(area.top<0){
		// }
		this.$message.error('校验未通过：'+err.errorFields[0].errors[0]);
	}, //页面定位到错误信息处 已基本实现  待开发优化
	guid() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
			return v.toString(16);
		});
	},//guid
	defaultpage(){
		return {
			defaultCurrent: 1,
			current: 1,
			defaultPageSize: 10,
			pageSize: 10,
			showQuickJumper: true,
			showSizeChanger: true,
			showTotal: (total, range) => `共${total}条`,
			total: 0,
		}
	},//page工厂函数
	applysDataTableChangeHandle(pagination) {
		this.pagination.current		= pagination.current;
		this.pagination.pageSize	= pagination.pageSize;
		this.getList();
	},//操作分页控制简易方式 列表获取必须是getList方法
	assignObj({data}){
		arguments.forEach((item,key)=>{
			if(key && item){
				item.forEach((v,k)=>{
					data[k]=v;
				})
			}
		})
	},//数据对象注入
}

export default {
	install(Vue){
		Vue.config.globalProperties.$cache=Cache;
		Vue.config.globalProperties.muajax=muajax;
		Vue.config.globalProperties.export_xls=export_xls;
		for(let key  in myData){
			Vue.config.globalProperties['$'+key]=myData[key];
		}
		for(let key  in myfun){
			Vue.config.globalProperties['$'+key]=myfun[key];
		}
	}
};