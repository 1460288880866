/*
 * 保存一些基础通用api
 * @Author:Mumu
 *
 */

import apiA from './api_planA'

export default {
	...apiA,
}