/**
 * 本地存储处理   在localStorage上扩展数据类型的支持，使其能处理字符串之外的值，
 * 本模块模块有自身的作用域，也可以设置自定义的作用域，不影响原本的localStorage设置的字段值，
 * 如果使用原生localStorage.clear()将会清除所有的本地缓存，包括本模块的值，应慎重使用或者只使用本模块设置和获取本地存储。
 * @param  {[type]} field 字段
 * @param  {[type]} value 值
 * @param  {[type]} scope 作用域
 * @return {[type]} [description]
 * 使用方法 获取值 this.$cache('fieldname' [,null,scope]);  this.$cache.get('fieldname' [,scope]);
 * 		   设置值 this.$cache('fieldname',value [,scope]);
 *         清除值 this.$cache('fieldname','' [,scope]);
 *         移除指定值 this.$cache.clean('fieldname' [,scope]);
 *         移除本模块某个作用域或者所有本模块设置的值 this.$cache.clean(['',scope]);
 * 会话缓存 this.$cache.se() this.$cache.se.get()  this.$cache.se.clean()
 * 			使用方式与上面相同  参数也完全相同
 */
 function stg(strage,field='',value,scope=''){
	if (!field) {
		console.error("cache 'field' is undefined");
		return;
	}
	scope=scope?scope+'_':'';
	let valuetype = typeof value;
	if (valuetype=="undefined" || value === null) {
		let res=strage.getItem(scope+field);
		if (!res) {
			return '';
		}
		let data='';
		try{
			data=JSON.parse(res);
		}catch(err){
			console.error(err);
		}
		return data.value||'';
	}else{
		strage.setItem(scope+field, JSON.stringify({
			ismumuvalue:true,
			type:valuetype,
			scope,
			value
		}));
	}
}
function ccstg(strage,field='',scope=''){
	scope=scope?scope+'_':'';
	if (field) {
		strage.removeItem(scope+field)
	}else{
		for (let i = 0; i < strage.length; i++) {
		 	let key = strage.key(i);
		 	let val = strage.getItem(key);
		 	try{
				let data=JSON.parse(val);
				if(data.ismumuvalue){
					if (!!scope) {
						if(data.scope==scope){
							strage.removeItem(key);
						}
					}else{
						strage.removeItem(key);
					}
				}
			}catch(err){
				console.error(err);
			}
		}
	}
}
// localStorage
function cache(field='',value,scope=''){
	return stg(localStorage,field,value,scope);
}
cache.get=function(field='',scope=''){
	return stg(localStorage,field,null,scope);
}
cache.clean=function(field='',scope='') {
	return ccstg(localStorage,field,scope);
}
// sessionStorage
cache.se=function(field='',value,scope='') {
	return stg(sessionStorage,field,value,scope);
}
cache.se.get=function(field='',scope=''){
	return stg(sessionStorage,field,null,scope);
}
cache.se.clean=function(field='',scope='') {
	return ccstg(sessionStorage,field,scope);
}
export default cache;