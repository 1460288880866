// 默认路由位置
const page = [
	{
		name: 'home',
		path: '/home',
		component: () => import('@/views/pages/home/index.vue'),
		requireAuth: true,
		meta: {
			title: '首页'
		}
	},
	{
		name: 'platform',
		path: '/platform',
		component: () => import('@/views/HomeView.vue'),
		requireAuth: true,
		meta: {
			title: '管理页面'
		}
	},
];
export default page