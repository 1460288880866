/**
 * 导出数据表
 * @Author mumu xinglinhe@qq.com
 * @param  {[type]} filename 文件名
 * @param  {[type]} data     数据
 * @return {[type]}          filename.xls
 */
function export_xls(filename,data){
    let str=`<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
            <head>
            <meta http-equiv=Content-Type content="text/html; charset=utf-8">
            <meta name=ProgId content=Excel.Sheet>
            <meta name=Generator content="Microsoft Excel 11">
            <!--[if gte mso 9]><xml>
             <o:DocumentProperties>
              <o:Author>微软用户</o:Author>
              <o:LastAuthor>微软用户</o:LastAuthor>
              <o:Created>2018-08-16T08:29:29Z</o:Created>
              <o:LastSaved>2018-08-16T08:36:41Z</o:LastSaved>
              <o:Company>微软中国</o:Company>
              <o:Version>11.9999</o:Version>
             </o:DocumentProperties>
            </xml><![endif]-->
            <style>
            <!--table
                {mso-displayed-decimal-separator:"\.";
                mso-displayed-thousand-separator:"\,";}
            @page
                {margin:1.0in .75in 1.0in .75in;
                mso-header-margin:.5in;
                mso-footer-margin:.5in;}
            tr
                {mso-height-source:auto;
                mso-ruby-visibility:none;}
            col
                {mso-width-source:auto;
                mso-ruby-visibility:none;}
            br
                {mso-data-placement:same-cell;}
            .style0
                {mso-number-format:General;
                text-align:general;
                vertical-align:middle;
                white-space:wrap;
                mso-rotate:0;
                mso-background-source:auto;
                mso-pattern:auto;
                color:windowtext;
                font-size:12.0pt;
                font-weight:400;
                font-style:normal;
                text-decoration:none;
                font-family:宋体;
                mso-generic-font-family:auto;
                mso-font-charset:134;
                border:none;
                mso-protection:locked visible;
                mso-style-name:常规;
                mso-style-id:0;}
            td
                {mso-style-parent:style0;
                padding-top:1px;
                padding-right:1px;
                padding-left:1px;
                mso-ignore:padding;
                color:windowtext;
                font-size:12.0pt;
                font-weight:400;
                font-style:normal;
                text-decoration:none;
                font-family:宋体;
                mso-generic-font-family:auto;
                mso-font-charset:134;
                mso-number-format:General;
                text-align:general;
                vertical-align:middle;
                border:none;
                mso-background-source:auto;
                mso-pattern:auto;
                mso-protection:locked visible;
                white-space:wrap;
                text-align:center;
                mso-rotate:0;}
            ruby
                {ruby-align:left;}
            rt
                {color:windowtext;
                font-size:9.0pt;
                font-weight:400;
                font-style:normal;
                text-decoration:none;
                font-family:宋体;
                mso-generic-font-family:auto;
                mso-font-charset:134;
                mso-char-type:none;
                display:none;}
            -->
            </style>
            <!--[if gte mso 9]><xml>
             <x:ExcelWorkbook>
              <x:ExcelWorksheets>
               <x:ExcelWorksheet>
                <x:Name>Sheet1</x:Name>
                <x:WorksheetOptions>
                 <x:DefaultRowHeight>285</x:DefaultRowHeight>
                 <x:Selected/>
                 <x:Panes>
                  <x:Pane>
                   <x:Number>3</x:Number>
                   <x:ActiveRow>5</x:ActiveRow>
                   <x:ActiveCol>7</x:ActiveCol>
                  </x:Pane>
                 </x:Panes>
                 <x:ProtectContents>False</x:ProtectContents>
                 <x:ProtectObjects>False</x:ProtectObjects>
                 <x:ProtectScenarios>False</x:ProtectScenarios>
                </x:WorksheetOptions>
               </x:ExcelWorksheet>
               <x:ExcelWorksheet>
                <x:Name>Sheet2</x:Name>
                <x:WorksheetOptions>
                 <x:DefaultRowHeight>285</x:DefaultRowHeight>
                 <x:ProtectContents>False</x:ProtectContents>
                 <x:ProtectObjects>False</x:ProtectObjects>
                 <x:ProtectScenarios>False</x:ProtectScenarios>
                </x:WorksheetOptions>
               </x:ExcelWorksheet>
               <x:ExcelWorksheet>
                <x:Name>Sheet3</x:Name>
                <x:WorksheetOptions>
                 <x:DefaultRowHeight>285</x:DefaultRowHeight>
                 <x:ProtectContents>False</x:ProtectContents>
                 <x:ProtectObjects>False</x:ProtectObjects>
                 <x:ProtectScenarios>False</x:ProtectScenarios>
                </x:WorksheetOptions>
               </x:ExcelWorksheet>
              </x:ExcelWorksheets>
              <x:WindowHeight>11205</x:WindowHeight>
              <x:WindowWidth>17235</x:WindowWidth>
              <x:WindowTopX>480</x:WindowTopX>
              <x:WindowTopY>45</x:WindowTopY>
              <x:ProtectStructure>False</x:ProtectStructure>
              <x:ProtectWindows>False</x:ProtectWindows>
             </x:ExcelWorkbook>
            </xml><![endif]-->
            </head>
            <body link=blue vlink=purple>
            <table x:str border=0 cellpadding=0 cellspacing=0 style='border-collapse: collapse;table-layout:fixed;'>
             <col>
             <tr>`;
             // 字段
             let fields=data.field.split(',');
             let l1=fields.length;
             for (let i = 0; i <l1; i++) {
                str+=`<td style="font-weight:bold;font-size:18px;">${fields[i]}</td>`;
             }
             str+="</tr>";
             // 值
             let l2=data.list.length;
             for (let i = 0; i <l2; i++) {
                str+="<tr style='height:auto;'>";
                for(let item in data.list[i]){
                  str+=`<td>${data.list[i][item]}</td>`;
                }
                str+="</tr>";
             }
            // 表尾
             str+=`<![if supportMisalignedColumns]>
             <tr height=0 style='display:none'>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
             </tr>
             <![endif]>
            </table>
            </body>
            </html>`;
       //encodeURIComponent解决中文乱码
      let uri = 'data:text/xls;charset=utf-8,\ufeff' + encodeURIComponent(str);
      //通过创建a标签实现
      var link = document.createElement("a");
      link.href = uri;
      //对下载的文件命名
      link.download =  filename+this.$timef(new Date(),'Y-m-d')+".xls";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
}

export default export_xls;