import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/components/map/assets/css/index.css';
import '@/components/map/assets/css/ol-overlay.less';
import '@/assets/fonts/css/font-define.less';
import '@/assets/css/index.less';
import '@/assets/css/iconfont.css';
import { DatePicker } from 'ant-design-vue';
import { Layout } from 'ant-design-vue'
import * as echarts from "echarts";
// import { BarChart } from 'echarts-gl'
import "echarts-gl";
import 'echarts-liquidfill'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import Myfun from './common/common-mu.js';

const app = createApp(App)
app.config.globalProperties.$echarts = echarts;
app.use(store)
app.use(router)
app.use(Myfun)
app.use(Antd)
// app.use(BarChart)
app.mount('#app');


// createApp(App).use(store).use(router).use(Antd).mount('#app')