/**
 * 本模块是由axios的实例创建，不污染axios环境。
 * 同步使用方式	this.muajax.apiname([params])  同步执行，直接赋值
 * 异步使用方式	this.muajax.apiname([params]).then(result=>{}).catch()  在then回调中接受结果
 * 快捷方法		this.muajax(url,[config],[callback])	默认为post方式，config与axios()参数相同  有callback时为异步方法，无callback时调用流程同上
 * 				this.muajax.get(url,[data],[callback])	固定请求方式为get,其他使用注意同上
 * 				this.muajax.post(url,[data],[callback])	固定请求方式为post,其他使用注意同上
 * 所有的api组放在commonapi中，如需拆分api文件在commonapi依次引入即可，如需动态载入子模块api方法使用muajax.initapi(apiConfigList)进行手动加载
 * @Author   Mumu
 * 典型示例
 * async ()=>{
 * 		let rs = await this.muajax({id:2});
 * 		console.log(rs);
 * }
 */

import apilis from './api/commonApi.js';
import cache from './cache-mu.js';
import axios from 'axios';
import router from '@/router';
import { Modal,message as Message } from 'ant-design-vue';

// import qs from 'qs';
var inst=axios.create({
	timeout:20000,
});
// inst.defaults.baseURL='/adminctr/';
// inst.defaults.baseURL='https://sck.onexonem.com:8200/adminctr/';
inst.defaults.baseURL=process.env.VUE_APP_API_BASE_URL;

/**
 * 通用方法  可以进行普通的异步
 * @param  {[type]}   url      url路径
 * @param  {[type]}   config   数据对象
 * @param  {Function} callback 回调函数  有回调为异步  无回调为同步,需等待结果
 * @return {[type]}            response  异步时无return
 */
function muajax(url,config={},callback){
	if (!callback) {
		let req=async ()=>{
			let response;
			try{
				await inst({
					url,
					method:'post',
					...config,
				});
			}catch(err){
				response = err;
			}
			return response;
		}
		return req();
	}else{
		inst({
			url,
			method:'post',
			...config,
		}).then(res=>{
			if(!!callback){
				callback(res);
			}
		}).catch(err=>{
			if(!!callback){
				callback(err);
			}
		});
	}
}
muajax.api=apilis;
muajax.inst=inst;
/**
 * get请求
 * @param  {[type]}   url      url路径
 * @param  {[type]}   data     数据对象
 * @param  {Function} callback 回调函数  有回调为异步  无回调为同步,需等待结果
 * @return {[type]}            response  异步时无return return1个请求token用于后面取消？
 */
muajax.get=(url,data={},callback)=>{
	let desc=data.desc;
	delete data.desc;
	if (!callback) {
		let req=async ()=>{
			let response;
			try{
				response=await inst.get(url,{
					params:data,
					desc
				});
			}catch(err){
				response = err;
			}
			return response;
		}
		return req();
	}else{
		inst.get(url,{
			params:data,
			desc
		}).then(res=>{
			if(!!callback){
				callback(res);
			}
		}).catch(err=>{
			if(!!callback){
				callback(err);
			}
		});
	}
}
/**
 * post请求
 * @param  {[type]}   url      url路径
 * @param  {[type]}   data     数据对象
 * @param  {Function} callback 回调函数  有回调为异步  无回调为同步,需等待结果
 * @return {[type]}            response 异步时无return
 */
muajax.post=(url,data={},callback)=>{
	let desc=data.desc;
	delete data.desc;
	if (!callback) {
		let req=async ()=>{
			let response;
			try{
				response=await inst.post(url,data,{desc});
			}catch(err){
				response = err;
			}
			return response;
		}
		return req();
	}else{
		inst.post(url,data,{desc}).then(res=>{
			if(!!callback){
				callback(res);
			}
		}).catch(err=>{
			if(!!callback){
				callback(err);
			}
		});
	}
}

/**
 * 其他已配置的请求实例
 * @param  {[type]} let k             in apilis 所有配置好的请求
 * @return {[type]}     [description]
 */
for(let k in apilis){
	let api=apilis[k];
	muajax[k]=async (data={},config={})=>{
		config.desc=api.desc;
		let response;
		try{
			if (api.method=='get' || api.method=='delete' || api.method=='options') {
				response=await inst[api.method](api.url||api.mockurl,{
					...config,
					params:data
				});
			}else{
				// post put patch
				response=await inst[api.method](api.url||api.mockurl,data,config);
			}
		}catch(err){
			// console.log(err);
			response = err;
		}
		// console.log(response);
		return response;
	}
}

// 动态载入其他api
muajax.initapi=function(mylist) {
	let myapi={mylist};
	for(let k in mylist){
		let api=mylist[k];
		myapi[k]=async (data={},config={})=>{
			config.desc=api.desc;
			let response;
			try{
				if (api.method=='get' || api.method=='delete' || api.method=='options') {
					response=await inst[api.method](api.url,{
						...config,
						params:data
					});
				}else{
					// post put patch
					response=await inst[api.method](api.url,data,config);
				}
			}catch(err){
				response = err;
			}
			// console.log(response);
			return response;
		}
	}
	myapi.inst=inst;
	myapi.get=muajax.get;
	myapi.post=muajax.post;
	return myapi;
}





// import { Toast,Dialog } from 'vant';

Message.config({top:'30%'});
// 请求拦截器
inst.interceptors.request.use(config=>{
	// 发起请求前 处理一下基本参数

	return config;
},err=>{
	// Message.destroy();
	// 请求错误 未找到目标服务器
	let error={
		status:0,
		code:0,
		msg:'请求目标服务器或网络异常'
	}
	if (err.response) {
		error.code=err.response.status;
	}
	Message.error({content:error.msg});
	return Promise.reject(error);
});
// 响应拦截器
inst.interceptors.response.use(res=>{
	// console.log(res);
	// Message.destroy();
	const {
		code,
		info,
		data,
		refresh_token
	} = res.data;
	switch(code){
		case 0:
			Message.error(info);
		break;
		case 401:
			Modal.destroyAll();
			Modal.info({
				title: '系统提示',
				content: '登录状态已过期, 请重新登录!',
				okText: '重新登录',
				onOk: () => {
					goLogin(true);
				}
			});
		break;
		case 1001:
			Message.error({content:'当前无权限操作'});
			// 无权限
			// router.push('/404');
		break;
		default:
			// token自动续期
			if (refresh_token) {
				setting.cacheToken(refresh_token);
			}
	}
	return res.data;
},err=>{
	// Message.destroy();
	// 请求失败 已找到目标服务器 但是服务未能正确处理请求
	let error={
		status:0
	};
	console.log(err.response)
	if (err.response) {
		switch(err.response.status){
			case 405	:	error.msg = '目标服务器已拒绝本次响应';	break;
			// case 500	:	error.msg = '目标服务器响应出错';			break;
			case 502	:	error.msg = '目标服务器无响应';			break;
			default:
				error.msg=err.response.data.msg||err.response.data.message||err.response.statusText;
		}
		error.code=err.response.status;
	}else{
		error.code=0;
		error.msg='目标服务器响应出错';
	}
	Message.error({content:error.msg});
	return Promise.reject(error);
});



/**
 * 跳转到登录页面
 */
function goLogin(reload) {
	if (reload) {
		location.replace('./login'); // 这样跳转避免再次登录重复注册动态路由
	} else {
		const path = router.currentRoute.path;
		router.push({
			path: './login',
			query: path && path !== '/' ? {
				form: path
			} : null
		});
	}
}


export default muajax;