import { createRouter, createWebHistory } from 'vue-router'
import page from './router.config.js'

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/layout/index.vue"),
    meta: {
        requireAuth: true,
    },
    children: page,
},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
